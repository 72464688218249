import { Card, CardContent } from '@repo/ui/components/Card.js'
import { Icon } from '@repo/ui/components/Icon.js'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@repo/ui/components/Tooltip.js'
import type { IconName } from '@repo/ui/icons/icons'

export const Statistic = ({
  label,
  icon,
  value,
  tooltip,
}: {
  label: string
  icon: IconName
  value: any
  tooltip?: string | React.ReactNode
}) => {
  return (
    <Card>
      <CardContent className="px-4 py-3">
        <div className="flex flex-row items-center justify-between">
          <div className="flex items-center gap-1">
            <span className="font-bold text-muted-foreground text-xxs uppercase tracking-widest">
              {label}
            </span>
            {tooltip ? (
              <Tooltip>
                <TooltipTrigger>
                  <Icon
                    name="circle-question"
                    className="size-3 text-muted-foreground"
                  />
                </TooltipTrigger>
                <TooltipContent>{tooltip}</TooltipContent>
              </Tooltip>
            ) : null}
          </div>
          <Icon name={icon} size="md" className="opacity-50" />
        </div>

        <div className="font-extrabold text-2xl" data-slot="value">
          {value}
        </div>
      </CardContent>
    </Card>
  )
}
